<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-users-alt"></i>
                </div>
              </div>
              <h5 class="title">เมนูจัดการข้อมูลผู้ใช้งาน</h5>
              <p class="border-bottom"></p>
              <h5 class="text-primary">วิธีแก้ไขบัญชีผู้ใช้งาน</h5>
            </div>
            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs
                  pills
                  justified
                  content-class="mt-4 pt-2"
                  nav-wrapper-class=""
                >
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">เว็บไซต์</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              คลิกที่แถบภาพและชื่อโปรไฟล์ของผู้ใช้งาน
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/profile/editprofile1.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">เลือก "Profile"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/profile/editprofile2.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ผู้ใช้งานแก้ไขข้อมูลในแบบฟอร์ม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/profile/editprofile3.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Save Change" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/profile/editprofile4.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ผู้ใช้งานสามารถเปลี่ยนรหัสผ่าน
                              โดยกรอกรหัสผ่านเก่าและรหัสผ่านใหม่
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal5>
                          <img
                            src="/images/screen/profile/editprofile5.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal5"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Save Password" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal6>
                          <img
                            src="/images/screen/profile/editprofile6.png"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal6"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/editprofile6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">แอปพลิเคชัน</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">คลิกเมนู setting</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal7>
                          <img
                            src="/images/screen/profile/profile_mobile1.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal7"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile1.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">คลิก Edit profile</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal8>
                          <img
                            src="/images/screen/profile/profile_mobile2.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal8"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile2.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ผู้ใช้งานแก้ไขข้อมูล และกดปุ่ม "Confirm"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal9>
                          <img
                            src="/images/screen/profile/profile_mobile3.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal9"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile3.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ระบบแจ้งเตือนแก้ไขข้อมูลส่วนตัวสำเร็จ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal10>
                          <img
                            src="/images/screen/profile/profile_mobile4.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal10"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile4.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ผู้ใช้งานสามารถเปลี่ยนรหัสผ่าน โดยคลิกปุ่ม "Change
                              password"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal11>
                          <img
                            src="/images/screen/profile/profile_mobile5.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal11"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile5.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ผู้ใช้งานกรอกรหัสผ่านเก่าและรหัสผ่านใหม่แล้วคลิกปุ่ม
                              "Confirm to update password"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal12>
                          <img
                            src="/images/screen/profile/profile_mobile6.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal12"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile6.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="
                              icon
                              text-center
                              rounded-circle
                              text-primary
                              mr-3
                            "
                          >
                            <h3 class="mb-0">7</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ระบบแจ้งเตือนเปลี่ยนรหัสผ่านสำเร็จ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal13>
                          <img
                            src="/images/screen/profile/profile_mobile7.jpg"
                            width="300"
                            class="
                              img-fluid
                              mx-auto
                              rounded-md
                              shadow-lg
                              d-block
                            "
                          />
                        </a>
                        <b-modal
                          id="modal13"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/profile/profile_mobile7.jpg"
                            width="300"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/libraryUser.vue";
export default {
  data() {
    return {
      content: "editProfile",
    };
  },
  components: {
    library,
  },
};
</script>
